import {
  FETCH_ADMIN_BEGIN,
  FETCH_ADMIN_SUCCESS,
  FETCH_ADMIN_FAILURE,
} from '../actions/adminActions';

const initialState = {
  credentials: [],
  credentialsLoading: false,
  credentialsError: null,
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADMIN_BEGIN:
      return {
        ...state,
        credentialsLoading: true,
        credentialsError: null,
      };

    case FETCH_ADMIN_SUCCESS:
      return {
        ...state,
        credentialsLoading: false,
        credentials: action.payload.credentials,
      };

    case FETCH_ADMIN_FAILURE:
      return {
        ...state,
        credentialsLoading: false,
        credentialsError: action.payload.error,
        credentials: [],
      };

    default:
      return state;
  }
};

export default adminReducer;
