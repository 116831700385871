/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchSettings } from "../redux/actions/settingActions";
import { fetchOrdersTodayAdmin } from "../redux/actions/orderActions";
import { postData, STORES } from "../util";
import "./AdminSettings.css";

const AdminSettings = ({
  closeSettingsMenu,
  settings,
  fetchSettings,
  ordersToday,
  fetchOrdersToday,
}) => {
  useEffect(() => {
    fetchSettings();
    fetchOrdersToday();
  }, []);
  const history = useHistory();
  let serverSettings = settings.settings[0];
  const whatStore = localStorage.getItem("selectedStoreAdmin");
  if (whatStore && whatStore.length > 1) {
    serverSettings = settings.settings.find((s) => s.store === whatStore);
  }

  const isClosed = serverSettings && serverSettings.isClosed === 1;
  const username = localStorage.getItem("username");
  const initPizzaAmount = serverSettings && serverSettings.maxOrders
    ? serverSettings.maxOrders
    : 250;
  const initPastaAmount = serverSettings && serverSettings.maxOrdersPasta
    ? serverSettings.maxOrdersPasta
    : 40;
  const initPastaAmount2 = serverSettings && serverSettings.maxOrdersPasta2
    ? serverSettings.maxOrdersPasta2
    : 40;
  const [pizzaAmount, setPizzaAmount] = useState(initPizzaAmount);
  const [pastaAmount, setPastaAmount] = useState(initPastaAmount);
  const [pastaAmount2, setPastaAmount2] = useState(initPastaAmount2);

  const openCloseStore = () => {
    const openClose = isClosed ? 0 : 1;
    const openCloseText = isClosed ? "öppna" : "stänga";

    Swal.fire({
      title: `Vill du verkligen ${openCloseText} för ordrar?`,
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#6dd1a0",
      cancelButtonColor: "#fa8e82",
      confirmButtonText: "Ja!",
      cancelButtonText: "Nej",
    }).then((result) => {
      if (result.value) {
        postData("/api/settings/togglestore", {
          value: openClose,
          store: whatStore,
        }).then((data) => {
          if (
            data.status === 403 &&
            data.errorMsg === "Error updating store open status"
          ) {
            Swal.fire({
              title: "",
              text: "Något gick fel vid uppdatering av öppettider, försök igen",
              icon: "error",
              confirmButtonColor: "#6dd1a0",
              confirmButtonText: "OK",
            });
          } else if (data.status === 200 || data.status === 204) {
            fetchSettings();
          }
        });
      }
    });
  };

  const addInfoText = () => {
    const updateVersion = Number(serverSettings.clientVersion) + 1;
    Swal.fire({
      title: "Lägg till/ändra info text",
      html: `<input type="text" id="info" class="swal2-input" placeholder="Info text">`,
      confirmButtonText: "Lägg till",
      focusConfirm: false,
      preConfirm: () => {
        const infoText = Swal.getPopup().querySelector("#info").value;
        return { infoText };
      },
    }).then((result) => {
      if (result && result.value && result.value.infoText) {
        postData("/api/settings/addinfo", {
          infoText: result.value.infoText,
          updateVersion,
        });
      } else {
        postData("/api/settings/addinfo", { infoText: "", updateVersion });
      }
    });
  };

  const logout = () => {
    localStorage.setItem("username", "");
    localStorage.setItem("hash", "");
    history.push("admin/login");
  };

  const adminSelectStore = async (store) => {
    localStorage.setItem("selectedStoreAdmin", store);
    await fetchSettings();
    window.location.reload(true);
  };

  const updateMaxSoldAmount = () => {
    postData("/api/settings/maxorderspizza", {
      value: pizzaAmount,
      store: whatStore,
    });
    postData("/api/settings/maxorderspasta", {
      value: pastaAmount,
      store: whatStore,
    });
    postData("/api/settings/maxorderspasta2", {
      value: pastaAmount2,
      store: whatStore,
    });
    fetchSettings();
    Swal.fire({
      title: "Maxantalen är uppdaterade",
      text: "",
      icon: "success",
      confirmButtonColor: "#6dd1a0",
      confirmButtonText: "OK",
    });
  };

  const whatDay = moment().isoWeekday();

  let selectedStore = localStorage.getItem("selectedStoreAdmin");

  if (
    (whatDay === 5 || whatDay === 7) &&
    localStorage.getItem("selectedStoreAdmin") === "Foodtruck #1"
  ) {
    selectedStore = STORES.FOODTRUCK1;
  }

  const ordersTodayPerStore = ordersToday.filter(
    (p) => p.store === selectedStore
  );

  const soldPizzasToday = _.sumBy(ordersTodayPerStore, "orderItemsPizzas");
  const soldPastasToday = _.sumBy(ordersTodayPerStore, "orderItemsPastas");
  const soldPastasToday2 = _.sumBy(ordersTodayPerStore, "orderItemsPastas2");

  const pizzasTodayTotal = _.sumBy(ordersToday, "orderItemsPizzas");
  const pastasTodayTotal = _.sumBy(ordersToday, "orderItemsPastas");
  const pastasTodayTotal2 = _.sumBy(ordersToday, "orderItemsPastas2");

  return (
    <div className="adminSettings_container">
      <h2 className="adminSettnigs_settingsText">Inställningar</h2>
      <div className="adminSettings_closeButtonContainer">
        <button
          type="button"
          className="adminSettings_closeButton"
          onClick={() => closeSettingsMenu(false)}
        >
          <FontAwesomeIcon
            className="adminSettings_closeButtonIcon"
            icon={faTimes}
          />
        </button>
      </div>

      <div className="adminSettings_settingsList">
        <div>
          <span className="adminSettings_closeStoreText">
            Öppna/stäng för ordrar
          </span>
          {isClosed ? (
            <button
              type="button"
              className="adminSettings_closeStoreButton"
              onClick={() => openCloseStore()}
            >
              Stängd
            </button>
          ) : (
            <button
              type="button"
              className="adminSettings_openStoreButton"
              onClick={() => openCloseStore()}
            >
              Öppen
            </button>
          )}
        </div>
        <br />
        <button
          type="button"
          className="adminSettings_addInfoButton"
          onClick={() => addInfoText()}
        >
          Lägg till info text
        </button>
        <br />
        <span className="adminSettings_closeStoreText">
          Pizzor idag:
          {soldPizzasToday} av{" "}
          <input
            type="text"
            className="adminSettings_inputAmount"
            onChange={(e) => setPizzaAmount(e.target.value)}
            value={pizzaAmount}
          />
        </span>
        <br />
        <span className="adminSettings_closeStoreText">
          Pasta 1 idag:
          {soldPastasToday} av{" "}
          <input
            type="text"
            className="adminSettings_inputAmount"
            onChange={(e) => setPastaAmount(e.target.value)}
            value={pastaAmount}
          />
        </span>
        <br />
        <span className="adminSettings_closeStoreText">
          Pasta 2 idag:
          {soldPastasToday2} av{" "}
          <input
            type="text"
            className="adminSettings_inputAmount"
            onChange={(e) => setPastaAmount2(e.target.value)}
            value={pastaAmount2}
          />
        </span>
        <br />
        <button
          type="button"
          className="adminSettings_updateButton"
          onClick={() => updateMaxSoldAmount()}
        >
          Uppdatera maxantal
        </button>

        {username === "sot_admin" && (
          <div className="adminSettings_adminSection">
            <hr />
            <span className="adminSettings_closeStoreText">
              Pizzor idag (alla restauranger): {pizzasTodayTotal}
              <br />
              Pastor idag (alla restauranger): {pastasTodayTotal}
              <br />
              Sallader idag (alla restauranger): {pastasTodayTotal2}
            </span>

            <br />
            <select
              className="adminSettings_storeSelect"
              onChange={(e) => adminSelectStore(e.target.value)}
              value={localStorage.getItem("selectedStoreAdmin")}
            >
              <option value={STORES.GJUTERIPORTEN_ST}>
                {STORES.GJUTERIPORTEN_ST}
              </option>
              <option value={STORES.POLHEMSGATAN_ST}>
                {STORES.POLHEMSGATAN_ST}
              </option>
              <option value={STORES.FOODTRUCK1}>Foodtruck #1</option>
              <option value={STORES.UPPSALA}>Uppsala Boländerna</option>
              <option value={STORES.UPPSALA2}>Hölö</option>
            </select>
            <br />

            <br />
            <button
              type="button"
              className="adminSettings_updateButton"
              onClick={() => history.push("admin/stats")}
            >
              Statistik
            </button>
          </div>
        )}
        <button
          type="button"
          className="adminSettings_logoutButton"
          onClick={() => logout()}
        >
          Logga ut
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.products.loading,
  settings: state.settings,
  isFetchingSettings: state.settings.loading,
  ordersToday: state.orders.ordersTodayAdmin,
  ordersTodayError: state.orders.ordersTodayErrorAdmin,
  ordersTodayLoading: state.orders.ordersTodayLoadingAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSettings: () => dispatch(fetchSettings()),
  fetchOrdersToday: () => dispatch(fetchOrdersTodayAdmin()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminSettings);
