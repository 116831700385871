/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-else-return */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable no-use-before-define */
/* eslint-disable object-curly-newline */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */

import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import { faPizzaSlice, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchOrdersTodayAdmin } from "../redux/actions/orderActions";
import { fetchSettings } from "../redux/actions/settingActions";
import { fetchUserCredentials } from "../redux/actions/adminActions";
import AdminSettings from "./AdminSettings";
import { postData, STORES } from "../util";
import NewOrderPopup from "./NewOrderPopup";
import "./Admin.css";

const Admin = ({
  fetchSettings,
  ordersToday,
  fetchOrdersToday,
  fetchUserCredentials,
}) => {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    fetchSettings();
    fetchOrdersToday();
    pollServerUpdates();
    whatStore();

    const interval = setInterval(async () => {
      const orders = await fetchOrdersToday();
      let activeOrders =
        orders &&
        orders.filter(
          (p) =>
            p.isDelivered === 0 &&
            p.store === localStorage.getItem("selectedStoreAdmin")
        );
      activeOrders = _.sortBy(activeOrders, (aO) => aO.id);
      if (
        activeOrders.length > localStorage.getItem("currentOrderSize") &&
        localStorage.getItem("username") !== "sot_admin"
      ) {
        showNewOrderInfo(activeOrders[activeOrders.length - 1]);
        forceUpdate();
      }
      localStorage.setItem("currentOrderSize", activeOrders.length);
    }, 30000);
    return () => clearInterval(interval);
  }, []);
  const history = useHistory();
  const [settingsSideMenu, showSettingsSideMenu] = useState(false);
  const username = localStorage.getItem("username");
  const [newOrderList, setNewOrderList] = useState([]);

  const pollServerUpdates = async () => {
    const creds = await fetchUserCredentials(username);
    if (creds && creds[0]) {
      validateUser(creds[0]);
    } else {
      history.push("/admin/login");
    }
  };

  const whatStore = () => {
    let store = "";
    switch (username) {
      case "sot_nykvarn":
        store = STORES.NYKVARN;
        break;
      case "sot_sodertalje":
        store = STORES.GJUTERIPORTEN_ST;
        break;
      case "sot_admin":
        store = "Admin";
        break;
      case "sot_ggs":
        store = STORES.MORABERG_ST;
        break;
      case "sot_polhem":
        store = STORES.POLHEMSGATAN_ST;
        break;
      case "sot_foodtruck1":
        store = STORES.FOODTRUCK1;
        break;
      case "sot_uppsala":
        store = STORES.UPPSALA;
        break;
      case "sot_uppsala2":
        store = STORES.UPPSALA2;
        break;
      case "sot_holo":
        store = STORES.UPPSALA2;
        break;
      default:
        store = "";
    }
    if (store !== "Admin") {
      localStorage.setItem("selectedStoreAdmin", store);
    }
  };

  const popMe = () => {
    const arr = newOrderList;
    arr.pop();
    setNewOrderList(arr);
    forceUpdate();
  };

  const showNewOrderInfo = (order) => {
    const arr = newOrderList;
    arr.push(
      <NewOrderPopup
        key={Date.now()}
        id={arr.length + 1}
        popMe={() => popMe()}
        order={order}
      />,
    );
    setNewOrderList(arr);
  };

  const closeSettingsMenu = (val) => {
    showSettingsSideMenu(val);
  };

  const isDelivered = (id, orderId) => {
    Swal.fire({
      title: `Vill du markera order ${orderId} som klar?`,
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#6dd1a0",
      cancelButtonColor: "#fa8e82",
      confirmButtonText: "Ja!",
      cancelButtonText: "Nej",
    }).then((result) => {
      if (result.value) {
        postData("/api/orders/delivered", { id }).then((data) => {
          if (
            data.status === 403 &&
            data.errorMsg === "Error updating order status"
          ) {
            Swal.fire({
              title: "",
              text: "Något gick fel vid uppdatering av order, försök igen",
              icon: "error",
              confirmButtonColor: "#6dd1a0",
              confirmButtonText: "OK",
            });
          } else if (data.status === 200) {
            fetchSettings();
            fetchOrdersToday();
          }
        });
      }
    });
  };

  const validateUser = (creds) => {
    const dbHash = creds.password;
    const userHash = localStorage.getItem("hash");
    if (dbHash !== userHash) {
      localStorage.setItem("username", "");
      localStorage.setItem("hash", "");
      history.push("admin/login");
    } else {
      fetchSettings();
      fetchOrdersToday();
    }
  };

  const ordersPerSlot = (time) => {
    if (localStorage.getItem("selectedStoreAdmin") === STORES.FOODTRUCK1) {
      const whatDay = moment().isoWeekday();
      let orders = ordersToday.filter(
        (p) =>
          p.deliveryTime === time &&
          p.store === localStorage.getItem("selectedStoreAdmin"),
      );
      if (whatDay === 5) {
        orders = ordersToday.filter(
          (p) => p.deliveryTime === time && p.store === STORES.FOODTRUCK1,
        );
      }
      const slotSum = _.sumBy(orders, "orderItemsPizzas");
      return slotSum;
    } else {
      const orders = ordersToday.filter(
        (p) =>
          p.deliveryTime === time &&
          p.store === localStorage.getItem("selectedStoreAdmin"),
      );
      const slotSum = _.sumBy(orders, "orderItemsPizzas");
      return slotSum;
    }
  };

  const selectedStore = () => {
    const whatDay = moment().isoWeekday();
    if (
      localStorage.getItem("selectedStoreAdmin") === STORES.FOODTRUCK1 &&
      (whatDay === 5 || whatDay === 7)
    ) {
      return STORES.FOODTRUCK1;
    } else {
      return localStorage.getItem("selectedStoreAdmin");
    }
  };

  const whatMaximum = () =>
    localStorage.getItem("selectedStoreAdmin") === STORES.FOODTRUCK1
      ? "2"
      : "6";
  const activeOrders = ordersToday.filter(
    (p) => p.isDelivered === 0 && p.store === selectedStore(),
  );
  const titleName =
    localStorage.getItem("selectedStoreAdmin") === STORES.FOODTRUCK1
      ? "Foodtruck #1"
      : localStorage.getItem("selectedStoreAdmin");
  return (
    <div className="admin_container">
      {settingsSideMenu && (
        <AdminSettings closeSettingsMenu={(val) => closeSettingsMenu(val)} />
      )}
      {newOrderList.map((popupStack) => popupStack)}

      <div className="admin_headerContainer">
        <button
          type="button"
          className="admin_settingsButton"
          onClick={() => closeSettingsMenu(!settingsSideMenu)}
        >
          <FontAwesomeIcon
            className="admin_settingsButtonIcon"
            icon={faPizzaSlice}
          />
        </button>
        <span className="admin_header">SOT - {titleName}</span>
        <span className="admin_pizzaCounter" />
      </div>

      <div className="admin_ordersTodayOuterContainer">
        {activeOrders.map((pizza, i) => (
          <div key={pizza.id}>
            {i === 0 && (
              <div className="admin_deliveryTimeContainer">
                <span className="admin_deliverTimeHeader">
                  {pizza.deliveryTime} - {ordersPerSlot(pizza.deliveryTime)} /{" "}
                  {whatMaximum()}
                </span>
              </div>
            )}
            {i > 0 &&
              pizza.deliveryTime !== activeOrders[i - 1].deliveryTime && (
                <div className="admin_deliveryTimeContainer">
                  <span className="admin_deliverTimeHeader">
                    {pizza.deliveryTime} - {ordersPerSlot(pizza.deliveryTime)} /{" "}
                    {whatMaximum()}
                  </span>
                </div>
            )}
            <div
              key={pizza.orderTimeStamp}
              className="admin_ordersTodayContainer"
            >
              <div className="admin_ordersTodayRow">
                <div className="admin_orderInfoContainer">
                  <span className="admin_orderId">[{pizza.orderId}]</span>
                  <span className="admin_pizzaName">{pizza.pizzas}</span>
                  <div className="admin_doneButtonContainer">
                    <button
                      type="button"
                      className="admin_doneButton"
                      onClick={() => isDelivered(pizza.id, pizza.orderId)}
                    >
                      <FontAwesomeIcon
                        className="admin_doneButtonIcon"
                        icon={faCheckCircle}
                      />
                    </button>
                  </div>
                </div>
                <div className="admin_orderInfoContainerSubRow">
                  <span className="admin_orderExtraInfo">
                    Order lagd {moment(pizza.orderTime).format("HH:mm")} av{" "}
                    {pizza.email} /{pizza.phoneNbr}. Summa: {pizza.orderPrice}
                    :-. Antal pizzor {pizza.orderItemsPizzas}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.products.loading,
  settings: state.settings,
  isFetchingSettings: state.settings.loading,
  ordersToday: state.orders.ordersTodayAdmin,
  ordersTodayError: state.orders.ordersTodayErrorAdmin,
  ordersTodayLoading: state.orders.ordersTodayLoadingAdmin,
  credentials: state.admin,
  isFetchingCredentials: state.admin.credentialsLoading,
  credentialsError: state.admin.credentialsError,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSettings: () => dispatch(fetchSettings()),
  fetchOrdersToday: () => dispatch(fetchOrdersTodayAdmin()),
  fetchUserCredentials: (username) => dispatch(fetchUserCredentials(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
