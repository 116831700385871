/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-spacing */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import Swal from 'sweetalert2';
import {ModalCheckout, Event} from '@bambora/checkout-sdk-web';
import {
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import InfoModal from './InfoModal';
import {fetchSettings} from '../redux/actions/settingActions';
import {
  postData, successSwal, errorSwal,
} from '../util';
import {clearCart, fetchCurrentOrders} from '../redux/actions/orderActions';
import './Checkout.css';
import * as Svgs from '../assets';
import FooterPayment from './FooterPayment';

const Checkout = ({
  settings,
  fetchSettings,
}) => {
  const [email, setEmail] = useState('');
  const [phoneNbr, setPhoneNbr] = useState('');
  const [campaign, setCampaign] = useState('');
  const [hasValidCode, setHasValidCode] = useState('');
  const [showCampignOk, setShowCampignOk] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [totalSum, setTotalSum] = useState(0);
  const [campaignInputBackground, setCampaignInputBackground] = useState('transparent');
  const [disableOrderButton, setDisableOrderButton] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('Välj');
  const [orderButtonText, setOrderButtonText] = useState('Slutför betalning');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const history = useHistory();
  const [store, setStore] = useState('Välj ställe för avhämtning');

  useEffect(() => {
    setStoredPaymentInfo();
    fetchSettings();
    setTotalSum(localStorage.getItem('orderSum'));

    const hasStore = localStorage.getItem('selectedStore');
    if (hasStore != 'null' && hasStore != null && hasStore !== '' && hasStore !== 'Välj ställe för avhämtning') {
      setStore(hasStore);
    }
  }, []);

  const storeSettings = settings && settings.settings.filter((s) => s.store === store);
  const serverSettings = storeSettings.length > 0 ? storeSettings[0] : settings.settings[0];

  const serverClientVersion = serverSettings && serverSettings.clientVersion;
  const storedSettings = JSON.parse(localStorage.getItem('settings'));
  const storedClientVersion = storedSettings && storedSettings.clientVersion;
  if (serverClientVersion && serverClientVersion != null && serverClientVersion !== storedClientVersion) {
    console.log('UPDATE VERSION');
    localStorage.setItem('orderList', JSON.stringify([]));
    localStorage.setItem('discountedOrderSum', 0);
    localStorage.setItem('discountedOrderList', JSON.stringify([]));
    localStorage.setItem('deliveryTime', null);
    localStorage.setItem('orderSum', 0);
    localStorage.setItem('selectedStore', 'Välj ställe för avhämtning');
    history.push('/');
    window.location.reload(true);
  }

  const isClosed = serverSettings && serverSettings.isClosed === 1;
  const deliveryTime = localStorage.getItem('deliveryTime');
  const orderList = JSON.parse(localStorage.getItem('orderList'));
  const orderSum = (list) => _.sumBy(list, 'price');

  const setStoredPaymentInfo = () => {
    const storedEmail = localStorage.getItem('email');
    const storedNumber = localStorage.getItem('phoneNbr');
    if (storedEmail) {
      setEmail(storedEmail);
    }
    if (storedNumber) {
      setPhoneNbr(storedNumber);
    }
  };

  const validateEmail = (testEmail) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(testEmail).toLowerCase());
  };

  const triggerCampaign = () => {
    postData('/api/campaigns/validatecode', {code: campaign}).then((res) => {
      if (res && res.campaigns && res.campaigns.length == 1) {
        const hasCampaign = res.campaigns[0];
        setShowCampignOk(true);
        if (hasCampaign.hasBeenUsedNumTimes < hasCampaign.canBeUsedNumTimes && hasCampaign.freePizzas > 0) {
          const discountedList = orderList.slice(hasCampaign.freePizzas);
          setTotalSum(orderSum(discountedList));
          localStorage.setItem('orderSum', orderSum(discountedList));
          setHasValidCode(campaign);
          setOrderButtonText('Lägg order med rabattkod');
        } else if (hasCampaign.hasBeenUsedNumTimes < hasCampaign.canBeUsedNumTimes && hasCampaign.discount > 0) {
          const discountedSum = Math.ceil(orderSum(orderList) * (1 - hasCampaign.discount / 100));
          localStorage.setItem('orderSum', discountedSum);
          setTotalSum(discountedSum);
          setHasValidCode(campaign);
        } else {
          errorSwal('Koden du angav är felaktig');
        }
      } else {
        errorSwal('Koden du angav är felaktig');
      }
    });
  };

  const orderWithCode = (order) => {
    postData('/api/orders/order', {order}).then((orderRes) => {
      if (orderRes.status == 200) {
        successSwal('Tack för din order med rabattkod. Vi skickar dig nu ett mail med bekräftelse på din order. Kontakta support@sot.nu om du inte fått något mail inom 3 minuter');
        localStorage.setItem('orderList', JSON.stringify([]));
        localStorage.setItem('discountedOrderSum', 0);
        localStorage.setItem('discountedOrderList', JSON.stringify([]));
        localStorage.setItem('deliveryTime', null);
        localStorage.setItem('orderSum', 0);
        history.push('/');
      } else {
        errorSwal('Något gick fel när vi skulle behandla din order. Försök igen eller kontakta support@sot.nu');
      }
    });
  };

  const orderNotAllowed = (data, pizzaCount, pastaCount, pastaCount2) => {
    if (data.errorMsg === 'Store is closed') {
      const pizzasLeft = data.orderInfo.maxOrders - (data.orderInfo.newOrderSize - pizzaCount);
      const pastasLeft = data.orderInfo.maxOrdersPasta - (data.orderInfo.newOrderSize - pastaCount);
      const pastasLeft2 = data.orderInfo.maxOrdersPasta2 - (data.orderInfo.newOrderSize2 - pastaCount2);
      let infoMsg = 'Vi har tyvärr stängt för ordrar just nu. Detta kan bero på att vi sålt slut på dagens pizzor. Eller att du besöker oss utanför våra öppettider.';
      if (data.orderInfo.newOrderSize > data.orderInfo.maxOrders && pizzasLeft > 0) {
        infoMsg = `Din order är tyvärr för stor för vad vi har utrymme kvar att baka för dagen. Max antal pizzor kvar att beställa är ${pizzasLeft}. Annars ber vi dig återkomma imorgon eller beställa från någon av våra andra upphämtningsställen!`;
      }
      if (data.orderInfo.newOrderSizePastas > data.orderInfo.maxOrdersPasta && pastasLeft > 0) {
        infoMsg = `Din order är tyvärr för stor för vad vi har pastarätter kvar. Max antal pastarätter att beställa är ${pastasLeft}. Testa gärna något av våra andra upphämtningsställen eller återkom imorgon!`;
      }
      if (data.orderInfo.newOrderSizePastas2 > data.orderInfo.maxOrdersPasta2 && pastasLeft2 > 0) {
        infoMsg = `Din order är tyvärr för stor för vad vi har pastarätter kvar. Max antal pastarätter att beställa är ${pastasLeft2}. Testa gärna något av våra andra upphämtningsställen eller återkom imorgon!`;
      }
      errorSwal(infoMsg);
    } else if (data.errorMsg === 'Campaign is used') {
      errorSwal('Denna rabattkod är förbrukad. Försök igen med en annan kod.');
    } else {
      errorSwal('Något oväntat gick fel när vi skulle behandla din order. Vänligen försök igen, testa att ladda om sidan och om du lagt sidan på hemskärmen så behöver du lägga dit sidan på nytt. Du måste ha swish och bank id på enheten du använder. Annars kontakta oss direkt på Facebook sot.nu eller mail support@sot.nu');
    }
  };

  const selectPayment = (val) => {
    setPaymentMethod(val);
    const obText = val === 'Swish' ? 'Slutför betalning med Swish' : 'Slutför betalning med kort';
    /* Hide for now, if problem occurs, take it back..
    if (val === 'Swish') {
      Swal.fire({
        title: '',
        text: `Det kan råda störningar med Swish. Om ni har problem med betalning vänligen använd kort eller kontakta support@sot.nu`,
        icon: 'info',
        confirmButtonColor: '#10AD7D',
        confirmButtonText: 'OK',
      });
    }
    */
    setOrderButtonText(obText);
  };

  const checkAcceptTerms = () => {
    setAcceptTerms(!acceptTerms);
  };

  const bamboraPayment = async (foodOrder) => {
    const orderId = foodOrder.orderId.replace(/-/g, '');
    const amount = foodOrder.orderPrice;
    const order = {id: orderId, amount};

    postData('/api/transactions/paymentbambora', order).then((res) => {
      const {token} = res.body;
      const checkout = new ModalCheckout(token);
      checkout.on(
        Event.Authorize,
        (payload) => {
          if (payload.data.txnid) {
            postData('/api/orders/order', {order: {...foodOrder, cardPayment: true}}).then(() => {
              localStorage.setItem('orderList', JSON.stringify([]));
              localStorage.setItem('discountedOrderSum', 0);
              localStorage.setItem('discountedOrderList', JSON.stringify([]));
              localStorage.setItem('deliveryTime', null);
              localStorage.setItem('orderSum', 0);
              localStorage.setItem('selectedStore', 'Välj ställe för avhämtning');
            });
            setTimeout(() => {
              Swal.fire({
                title: 'Tack för din order',
                text: `Din order med ordernummer: ${orderId.substring(orderId.length - 4, orderId.length)} är nu betald och vi har skickat dig ett mail med kvitto och orderdetaljer.`,
                icon: 'success',
                confirmButtonColor: '#10AD7D',
                confirmButtonText: 'OK',
              }).then((pressOk) => {
                if (pressOk) {
                  history.push('/');
                }
              });
            }, 3000);
          }
        },
      );
      checkout.show();
    });
  };

  const placeOrder = async () => {
    if (isClosed) {
      errorSwal('Vi har tyvärr stängt just nu. Detta kan bero på att vi sålt slut på dagens pizzor. Eller att du besöker oss utanför våra öppettider.');
      return;
    }

    if (!acceptTerms) {
      errorSwal('Du måste acceptera köpvillkoren för att slutföra köpet.');
      return;
    }

    if (paymentMethod === 'Välj') {
      errorSwal('Du måste välja en betalningsmetod.');
      return;
    }

    setDisableOrderButton(true);
    const orderSumVal = localStorage.getItem('orderSum');
    if (validateEmail(email)
      && !isClosed
      && localStorage.getItem('selectedStore') !== 'Välj'
      && localStorage.getItem('selectedStore') !== ''
      && localStorage.getItem('selectedStore') != null
      && localStorage.getItem('selectedStore') !== 'null') {
      const orderCount = orderList.filter((p) => p.pizzaNbr !== 99).length;
      const pizzaC1 = orderList.filter((p) => p.pizzaNbr >= 1 && p.pizzaNbr <= 95).length;
      const pizzaLussoCount = orderList.filter((p) => p.pizzaNbr >= 200 && p.pizzaNbr <= 299).length;

      const pastaCount = orderList.filter((p) => p.pizzaNbr >= 300 && p.pizzaNbr <= 399).length;
      const salladCount = orderList.filter((p) => p.pizzaNbr >= 400 && p.pizzaNbr <= 499).length;
      const pastaCount2 = salladCount; // orderList.filter((p) => p.pizzaNbr === 71).length;
      const dessertCount = orderList.filter((p) => p.pizzaNbr >= 500 && p.pizzaNbr <= 599).length;
      const sodaCount = orderList.filter((p) => p.pizzaNbr === 98).length;

      const pizzaCount = pizzaC1 + pizzaLussoCount + dessertCount;

      const itemCount = (item) => _.countBy(orderList, {name: item.name}).true;

      let orderToMail = '';
      let pizzasAndAmount = '';
      // const sortedByName = orderList.sort((a, b) => a.name.localeCompare(b.name));


      for (let i = 0; i < orderList.length; i++) {
        const {name, extra, price} = orderList[i];
        orderToMail += `${name} ${extra} ${price}:-<br/>`;
        pizzasAndAmount += `${name} ${extra} ${price}, `;
      }

      const orderIdDate = moment().format('MDD');
      const randomNbr = Math.ceil(Math.random() * (9999999 - 1000000) + 1000000);
      const orderId = `${orderIdDate}-${randomNbr}`;
      const freeOrder = hasValidCode.length > 4 && orderSumVal == 0;
      const store = localStorage.getItem('selectedStore');
      const {userAgent} = window.navigator;
      const order = {
        orderId,
        pizzas: pizzasAndAmount.slice(0, -2),
        orderPrice: orderSumVal,
        deliveryTime: localStorage.getItem('deliveryTime'),
        deliveryTimestamp: localStorage.getItem('deliveryTimestamp'),
        email,
        orderItemsAll: orderCount,
        orderItemsPizzas: pizzaCount,
        orderItemsPastas: pastaCount,
        orderItemsPastas2: pastaCount2,
        orderDate: moment().format('YYYY-MM-DD'),
        campaign: hasValidCode,
        pizzaListMail: orderToMail,
        freeOrder,
        store,
        userAgent,
        lunchTime: localStorage.getItem('lunchTime') === 'true',
        sodaCount,
        phoneNbr,
      };

      localStorage.setItem('email', email);
      localStorage.setItem('phoneNbr', phoneNbr);

      postData('/api/orders/orderavailable', {order}).then(
        (data) => {
          if (data.status === 403) {
            return orderNotAllowed(data, pizzaCount, pastaCount, pastaCount2);
          }
          if (data.status === 200 && order.deliveryTime != 'null' && order.deliveryTime.includes(':')) {
            if (freeOrder) {
              localStorage.setItem('lastOrderId', orderId);
              orderWithCode(order);
            } else if (!freeOrder && phoneNbr.length === 10 && paymentMethod === 'Swish') {
              if (hasValidCode.length === 0) {
                let nbr = phoneNbr.substring(1);
                nbr = `46${nbr}`;
                const paymentData = {
                  payer: nbr, amount: orderSumVal, reference: orderId, order,
                };
                history.push('/payment', paymentData);
              } else if (hasValidCode.length > 4 && !freeOrder) {
                let nbr = phoneNbr.substring(1);
                nbr = `46${nbr}`;
                const paymentData = {
                  payer: nbr, amount: orderSumVal, reference: orderId, order,
                };
                history.push('/payment', paymentData);
              } else {
                errorSwal('Något oväntat gick fel försök att lägga ordern på nytt. Om du behöver hjälp kontakta oss på support@sot.nu');
              }
            } else if (!freeOrder && paymentMethod === 'Kort') {
              return bamboraPayment(order);
            } else {
              errorSwal('Något oväntat gick fel. Kontrollera att mail och telefonnummer stämmer. Annars kontakta support@sot.nu');
            }
          } else {
            errorSwal('Något oväntat gick fel försök att lägga ordern på nytt. Om du behöver hjälp kontakta oss på support@sot.nu').then(() => {
              window.location.reload(true);
            });
          }
          return null;
        },
      );
    } else {
      let text = 'Något gick fel när vi skulle slutföra din order. Försök igen';
      if (!validateEmail(email)) {
        text = 'Din email adress verkar inte stämma, kontrollera och försök igen';
      } else if (localStorage.getItem('selectedStore') === 'Välj' || localStorage.getItem('selectedStore') == null || localStorage.getItem('selectedStore') === 'null') {
        text = 'Du måste välja vilken restaurang du vill hämta pizzorna på. Det väljer du på första sidan';
      }
      errorSwal(text);
    }
    setTimeout(() => setDisableOrderButton(false), 3000);
  };

  return (
    <div>
      {showInfoModal && (
        <InfoModal closeInfoModal={() => setShowInfoModal(false)} />
      )}
      <div className="cart_headerRow">
        <h2 className="header2">Betalning</h2>
        <div className="cart_headerCheckout" />
        <div className="cart_headerBackButtonContainer">
          <button type="button" className="pizzas_headerBackButton" onClick={() => history.push('/cart')}>
            <img src={Svgs.leftArrow} alt="" />
          </button>
        </div>
      </div>

      <div>
        <div className="checkout_enterEmailContainer">
          <span className="checkout_emailText">Ange mail för orderbekräftelse och kvitto</span>
          <input type="email" className="checkout_enterEmailInput" placeholder="Ange email" onChange={(e) => setEmail(e.target.value)} value={email} />
        </div>

        <div className="checkout_storePickerContainer">
          <label className="checkout_storePickerLabel">Restaurang för avhämtning</label>
          <span className="checkout_selectedStore">{localStorage.getItem('selectedStore')}</span>
        </div>

        <div className="checkout_enterCampaignContainer">
          <label className="checkout_campaignText">Rabattkod</label>
          <div className="checkout_inputAndIcon">
            <input type="text" className="checkout_enterCampaignInput" style={{background: campaignInputBackground}} placeholder="Ange rabattkod" onChange={(e) => setCampaign(e.target.value)} value={campaign} />
            {showCampignOk && (
              <div className="checkout_campaignContainer">
                <FontAwesomeIcon className="checkout_campaignOkIcon" icon={faCheckCircle} />
              </div>
            )}
          </div>
          {campaign.length > 0 && (
            <button type="button" className="checkout_useCampaignButton" onClick={() => triggerCampaign()}>Använd kod</button>
          )}
        </div>

        <div className="checkout_storePickerContainer">
          <label className="checkout_storePickerLabel">Välj betalningsmetod</label>
          <select className="checkout_storePickerDropdown" onChange={(e) => selectPayment(e.target.value)} value={paymentMethod}>
            <option value="Välj">Välj</option>
            <option value="Swish">Swish</option>
          </select>
        </div>

        {paymentMethod === 'Swish' && (
          <div className="checkout_enterEmailContainer">
            <label className="checkout_campaignText">Telefonnummer för Swish</label>
            <input type="email" className="checkout_enterEmailInput" placeholder="Ange telefonnummer" onChange={(e) => setPhoneNbr(e.target.value)} value={phoneNbr} />
          </div>
        )}

        <div>
          <div className="checkout_paymentContainer">
            <div>
              <span className="checkout_paymentTerms" onClick={() => setShowInfoModal(true)}><u>Köpvillkor</u></span>
              <br />

              <div className="checkout_acceptTermsDiv">
                <label className="checkout_acceptLabel">
                  Jag accepterar köpvillkoren
                  {' '}
                  <input
                    name="acceptTerms"
                    type="checkbox"
                    className="checkout_acceptCheck"
                    checked={acceptTerms}
                    onChange={() => checkAcceptTerms()}
                  />
                </label>
              </div>

            </div>
          </div>

          <div className="checkout_paymentContainer2">
            <span className="checkout_paymentSumText">Summa</span>
            <span className="checkout_paymentSumSum">
              {totalSum}
              {' '}
              kr
            </span>
          </div>

          {paymentMethod === 'Välj' && (
            <button type="button" className="checkout_paymentButton_disabled" onClick={() => placeOrder()}>Slutför betalning</button>
          )}

          {paymentMethod === 'Swish' && (
            <button type="button" className={disableOrderButton || !acceptTerms ? 'checkout_paymentButton_disabled' : 'checkout_paymentButton'} onClick={() => placeOrder()}>{orderButtonText}</button>
          )}

          {paymentMethod === 'Kort' && (
            <button type="button" className={disableOrderButton || !acceptTerms ? 'checkout_paymentButton_disabled' : 'checkout_paymentButton'} onClick={() => placeOrder()}>{orderButtonText}</button>
          )}

          {isClosed && (
            <div className="closedInfo">
              <span className="closedInfoText">Stängt</span>
              <br />
              <br />
            </div>
          )}

        </div>

      </div>
      <div className="checkout_deadSpace" />
      <FooterPayment />
    </div>
  );
};

const mapStateToProps = (state) => ({
  products: state.products.items,
  isFetingProducts: state.products.loading,
  error: state.products.error,
  settings: state.settings,
  isFetchingSettings: state.settings.loading,
  orderList: state.orders,
  ordersToday: state.orders.ordersToday,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSettings: () => dispatch(fetchSettings()),
  clearCart: () => dispatch(clearCart()),
  fetchCurrentOrders: (time) => dispatch(fetchCurrentOrders(time)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Checkout);
