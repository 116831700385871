import { baseUrl, API_KEY, handleErrors } from '../../util';

export const FETCH_PRODUCTS_BEGIN = 'FETCH_PRODUCTS_BEGIN';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

export const fetchProductsBegin = () => ({
  type: FETCH_PRODUCTS_BEGIN,
});

export const fetchProductsSuccess = (products) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: { products },
});

export const fetchProductsFailure = (error) => ({
  type: FETCH_PRODUCTS_FAILURE,
  payload: { error },
});

export const fetchProducts = () => (dispatch) => {
  dispatch(fetchProductsBegin());
  return fetch(`${baseUrl}/api/pizzas`, {
    headers: {
      'Content-Type': 'application/json',
      API_KEY,
    },
    mode: 'cors',
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((json) => {
      dispatch(fetchProductsSuccess(json.pizzas));
      return json.pizzas;
    })
    .catch((error) => dispatch(fetchProductsFailure(error)));
};
