/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
/* eslint-disable object-curly-spacing */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import './NewOrderPopup.css';

const NewOrderPopup = ({id, popMe, order}) => {
  return (
    <div className="newOrderPopup_outer_div">
      <h3 className="newOrderPopup_header_text">Ny order! ({id})</h3>
      <div className="payment_order_init_div">

        <div>
          <span>
            Ordernummer:
            {' '}
            {order.orderId.substring(4)}
          </span>
          <br />
          <span>
            Upphämtas:
            {' '}
            {order.deliveryTime}
          </span>
          <p dangerouslySetInnerHTML={{__html: order.pizzaListMail}} />
          <span>
            Upphämtningsplats:
            {' '}
            {order.store}
          </span>
          <br />
          {order.campaign.length > 0 && (
            <div>
              <span>
                Rabattkod:
                {' '}
                {order.campaign}
              </span>
              <br />
            </div>
          )}
          <span>
            Mail
            {' '}
            {order.email}
          </span>
          <br />
          <span>
            Telefon
            {' '}
            {order.phoneNbr}
          </span>
          <br />
        </div>
      </div>
      <div className="newOrderPopup_button_container">
        <button className="newOrderPopup_button" onClick={() => popMe()}>OK</button>
      </div>
    </div>
  );
};


export default NewOrderPopup;
