/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { postData } from "../util";
import "./AdminLogin.css";

const AdminLogin = () => {
  useEffect(() => {
  }, []);
  const history = useHistory();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const login = () => {
    postData("/api/admin/login", { username, password }).then(
      (data) => {
        if (data.status === 403 && data.errorMsg === "Wrong password") {
          Swal.fire({
            title: "",
            text: "Fel lösenord, försök igen",
            icon: "error",
            confirmButtonColor: "#6dd1a0",
            confirmButtonText: "OK",
          });
        } else if (data.status === 403 && data.errorMsg === "No user") {
          Swal.fire({
            title: "",
            text: "Denna användare finns ej",
            icon: "error",
            confirmButtonColor: "#6dd1a0",
            confirmButtonText: "OK",
          });
        } else if (data.status === 200) {
          const uname = data.credentials[0].username;
          const pass = data.credentials[0].password;
          localStorage.setItem("username", uname);
          localStorage.setItem("hash", pass);
          history.push("/admin");
        }
      },
    );
  };

  return (
    <div>
      <div className="adminLogin_headerContainer">
        <span className="admin_header">SOT - Admin</span>
      </div>
      <div className="adminLogin_loginContainer">
        <div className="adminLogin_loginForm">
          <label className="adminLogin_label" htmlFor="username">Användarnamn</label>
          <input type="text" className="adminLogin_input" name="username" placeholder="Användarnamn" onChange={(e) => setUsername(e.target.value)} value={username} />
          <label className="adminLogin_label" htmlFor="password">Lösenord</label>
          <input type="password" className="adminLogin_input" name="password" placeholder="Lösenord" onChange={(e) => setPassword(e.target.value)} value={password} />
          <button type="button" className="adminLogin_loginButton" onClick={() => login()}>Logga in</button>
        </div>
      </div>
    </div>
  );
};


export default connect(null)(AdminLogin);
