import { baseUrl, API_KEY, handleErrors } from '../../util';

export const FETCH_SETTINGS_BEGIN = 'FETCH_SETTINGS_BEGIN';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE';

export const fetchSettingsBegin = () => ({
  type: FETCH_SETTINGS_BEGIN,
});

export const fetchSettingsSuccess = (settings) => ({
  type: FETCH_SETTINGS_SUCCESS,
  payload: { settings },
});

export const fetchSettingsFailure = (error) => ({
  type: FETCH_SETTINGS_FAILURE,
  payload: { error },
});

export const fetchSettings = () => (dispatch) => {
  dispatch(fetchSettingsBegin());
  return fetch(`${baseUrl}/api/settings`, {
    headers: {
      'Content-Type': 'application/json',
      API_KEY,
    },
    mode: 'cors',
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((json) => {
      dispatch(fetchSettingsSuccess(json.settings));
      return json.settings;
    })
    .catch((error) => dispatch(fetchSettingsFailure(error)));
};
