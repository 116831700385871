import { baseUrl, API_KEY, handleErrors } from '../../util';

export const FETCH_ADMIN_BEGIN = 'FETCH_ADMIN_BEGIN';
export const FETCH_ADMIN_SUCCESS = 'FETCH_ADMIN_SUCCESS';
export const FETCH_ADMIN_FAILURE = 'FETCH_ADMIN_FAILURE';

export const fetchAdminBegin = () => ({
  type: FETCH_ADMIN_BEGIN,
});

export const fetchAdminSuccess = (credentials) => ({
  type: FETCH_ADMIN_SUCCESS,
  payload: { credentials },
});

export const fetchAdminFailure = (error) => ({
  type: FETCH_ADMIN_FAILURE,
  payload: { error },
});

export const fetchUserCredentials = (username) => (dispatch) => {
  dispatch(fetchAdminBegin());
  return fetch(`${baseUrl}/api/admin/validate`, {
    headers: {
      'Content-Type': 'application/json',
      API_KEY,
    },
    method: 'POST',
    body: JSON.stringify({ username }),
    mode: 'cors',
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((json) => {
      dispatch(fetchAdminSuccess(json.credentials));
      return json.credentials;
    })
    .catch((error) => dispatch(fetchAdminFailure(error)));
};
