import React from 'react';
import logo from '../assets/spin.gif';
import './Loader.css';

const Loader = () => (
  <div className="loader_container">
    <img src={logo} alt="" className="loader_spinner" />
  </div>
);

export default Loader;
