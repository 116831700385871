import {
  FETCH_SETTINGS_BEGIN,
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_FAILURE,
} from '../actions/settingActions';

const initialState = {
  settings: [],
  loading: false,
  error: null,
};

const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SETTINGS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: action.payload.settings,
      };

    case FETCH_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        settings: [],
      };

    default:
      return state;
  }
};

export default settingReducer;
