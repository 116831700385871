/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Svgs from "../assets";
import "./Footer.css";

const FooterPayment = () => {
  const facebookUrl = "https://www.facebook.com/sot.nu";
  const instagramUrl = "https://www.instagram.com/sot.nu/";

  const gotoFacebook = () => {
    window.open(facebookUrl, "_blank");
  };
  const gotoInstagram = () => {
    window.open(instagramUrl, "_blank");
  };

  return (
    <div className="footer_div_payment">
      <div>
        <div className="footer_paymentLogos">
          <img src={Svgs.swish} alt="" />
          {/*
          <img src={Svgs.visa} alt="" />
          <img src={Svgs.mastercard} alt="" />
        */}
        </div>
        <span className="footer_spanText">
          Från 00:01 kan ni lägga dagens ordrar.
        </span>
        <br />
        <span className="footer_spanText">
          Vill du beställa mer än 6 pizzor behöver
          <br />
          du lägga flera beställningar.
        </span>
        <br />
        <span className="footer_spanText">
          Vid frågor eller problem,
          <br /> kontakta oss på{" "}
          <a className="footer_support_mail" href="mailto:support@sot.nu">
            support@sot.nu
          </a>
          <br />
          Sotig AB Orgnr: 559213-5098
          <br />
        </span>
      </div>
      <div className="footer_social">
        <span className="footer_follow_us">FÖLJ OSS!</span>
        <div className="footer_social_icons">
          <button
            type="button"
            className="footer_iconButton"
            onClick={() => gotoFacebook()}
          >
            <FontAwesomeIcon
              className="footer_facebookIcon"
              icon={faFacebook}
            />
          </button>
          <button
            type="button"
            className="footer_iconButton"
            onClick={() => gotoInstagram()}
          >
            <FontAwesomeIcon
              className="footer_instagramIcon"
              icon={faInstagram}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default FooterPayment;
