import {combineReducers} from 'redux';
import orders from './reducers/orderReducer';
import products from './reducers/productReducer';
import settings from './reducers/settingReducer';
import admin from './reducers/adminReducer';
import campaigns from './reducers/campaignsReducer';

export default combineReducers({
  orders,
  products,
  settings,
  admin,
  campaigns,
});
