import {
  ADD_ITEM_TO_CART,
  REMOVE_ITEM_FROM_CART,
  CLEAR_CART,
  FETCH_CURRENT_ORDERS_BEGIN,
  FETCH_CURRENT_ORDERS_FAILURE,
  FETCH_CURRENT_ORDERS_SUCCESS,
  FETCH_ORDERS_TODAY_BEGIN,
  FETCH_ORDERS_TODAY_FAILURE,
  FETCH_ORDERS_TODAY_SUCCESS,
  FETCH_ORDERS_TODAY_BEGIN_ADMIN,
  FETCH_ORDERS_TODAY_FAILURE_ADMIN,
  FETCH_ORDERS_TODAY_SUCCESS_ADMIN,

} from '../actions/orderActions';

const initialState = {
  orderList: [],
  currentOrders: [],
  currentOrdersLoading: true,
  currentOrdersError: null,
  ordersToday: [],
  ordersTodayLoading: true,
  ordersTodayError: null,
  ordersTodayAdmin: [],
  ordersTodayLoadingAdmin: true,
  ordersTodayErrorAdmin: null,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM_TO_CART:
      return {
        ...state,
        orderList: [...state.orderList, action.item],
      };
    case REMOVE_ITEM_FROM_CART:
      return {
        ...state,
        orderList: [...state.orderList.slice(0, action.item.id), ...state.orderList.slice(action.item.id + 1)],
      };
    case CLEAR_CART:
      return [];
    case FETCH_CURRENT_ORDERS_BEGIN:
      return {
        ...state,
        currentOrdersLoading: true,
      };
    case FETCH_CURRENT_ORDERS_SUCCESS:
      return {
        ...state,
        currentOrdersLoading: false,
        currentOrders: action.currentOrders,
      };
    case FETCH_CURRENT_ORDERS_FAILURE:
      return {
        ...state,
        currentOrdersLoading: false,
        currentOrdersError: action.currentOrdersError,
        currentOrders: [],
      };
    case FETCH_ORDERS_TODAY_BEGIN:
      return {
        ...state,
        ordersTodayLoading: true,
      };
    case FETCH_ORDERS_TODAY_SUCCESS:
      return {
        ...state,
        ordersTodayLoading: false,
        ordersToday: action.ordersToday,
      };
    case FETCH_ORDERS_TODAY_FAILURE:
      return {
        ...state,
        ordersTodayLoading: false,
        ordersTodayError: action.ordersTodayError,
        ordersToday: [],
      };
    case FETCH_ORDERS_TODAY_BEGIN_ADMIN:
      return {
        ...state,
        ordersTodayLoadingAdmin: true,
      };
    case FETCH_ORDERS_TODAY_SUCCESS_ADMIN:
      return {
        ...state,
        ordersTodayLoadingAdmin: false,
        ordersTodayAdmin: action.ordersTodayAdmin,
      };
    case FETCH_ORDERS_TODAY_FAILURE_ADMIN:
      return {
        ...state,
        ordersTodayLoadingAdmin: false,
        ordersTodayErrorAdmin: action.ordersTodayErrorAdmin,
        ordersTodayAdmin: [],
      };
    default:
      return state;
  }
};

export default orderReducer;
