import { baseUrl, API_KEY, handleErrors } from '../../util';

export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';
export const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const FETCH_CURRENT_ORDERS_BEGIN = 'FETCH_CURRENT_ORDERS_BEGIN';
export const FETCH_CURRENT_ORDERS_FAILURE = 'FETCH_CURRENT_ORDERS_FAILURE';
export const FETCH_CURRENT_ORDERS_SUCCESS = 'FETCH_CURRENT_ORDERS_SUCCESS';

export const FETCH_ORDERS_TODAY_BEGIN = 'FETCH_ORDERS_TODAY_BEGIN';
export const FETCH_ORDERS_TODAY_FAILURE = 'FETCH_ORDERS_TODAY_FAILURE';
export const FETCH_ORDERS_TODAY_SUCCESS = 'FETCH_ORDERS_TODAY_SUCCESS';

export const FETCH_ORDERS_TODAY_BEGIN_ADMIN = 'FETCH_ORDERS_TODAY_BEGIN_ADMIN';
export const FETCH_ORDERS_TODAY_FAILURE_ADMIN = 'FETCH_ORDERS_TODAY_FAILURE_ADMIN';
export const FETCH_ORDERS_TODAY_SUCCESS_ADMIN = 'FETCH_ORDERS_TODAY_SUCCESS_ADMIN';

export const addItemToCart = (item) => ({
  type: ADD_ITEM_TO_CART,
  item,
});

export const removeItemFromCart = (item) => ({
  type: REMOVE_ITEM_FROM_CART,
  item,
});

export const clearCart = (orderList) => ({
  type: CLEAR_CART,
  orderList,
});

export const fetchCurrentOrdersBegin = () => ({
  type: FETCH_CURRENT_ORDERS_BEGIN,
});

export const fetchCurrentOrdersSuccess = (currentOrders) => ({
  type: FETCH_CURRENT_ORDERS_SUCCESS,
  currentOrders,
});

export const fetchCurrentOrdersFailure = (currentOrdersError) => ({
  type: FETCH_CURRENT_ORDERS_FAILURE,
  currentOrdersError,
});

export const fetchOrdersTodayBegin = () => ({
  type: FETCH_ORDERS_TODAY_BEGIN,
});

export const fetchOrdersTodaySuccess = (ordersToday) => ({
  type: FETCH_ORDERS_TODAY_SUCCESS,
  ordersToday,
});

export const fetchOrdersTodayFailure = (ordersTodayError) => ({
  type: FETCH_ORDERS_TODAY_FAILURE,
  ordersTodayError,
});

export const fetchOrdersTodayBeginAdmin = () => ({
  type: FETCH_ORDERS_TODAY_BEGIN_ADMIN,
});

export const fetchOrdersTodaySuccessAdmin = (ordersTodayAdmin) => ({
  type: FETCH_ORDERS_TODAY_SUCCESS_ADMIN,
  ordersTodayAdmin,
});

export const fetchOrdersTodayFailureAdmin = (ordersTodayErrorAdmin) => ({
  type: FETCH_ORDERS_TODAY_FAILURE_ADMIN,
  ordersTodayErrorAdmin,
});


export const fetchCurrentOrders = (deliveryTime) => (dispatch) => {
  const data = JSON.stringify({ deliveryTime });
  dispatch(fetchCurrentOrdersBegin());
  return fetch(`${baseUrl}/api/orders/deliverytime`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      API_KEY,
    },
    mode: 'cors',
    body: data,
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((json) => {
      dispatch(fetchCurrentOrdersSuccess(json.orders));
      return json.orders;
    })
    .catch((error) => dispatch(fetchCurrentOrdersFailure(error)));
};


export const fetchOrdersToday = () => (dispatch) => {
  dispatch(fetchOrdersTodayBegin());
  return fetch(`${baseUrl}/api/orders/date/today`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      API_KEY,
    },
    mode: 'cors',
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((json) => {
      dispatch(fetchOrdersTodaySuccess(json.orders));
      return json.orders;
    })
    .catch((error) => dispatch(fetchOrdersTodayFailure(error)));
};

export const fetchOrdersTodayAdmin = () => (dispatch) => {
  dispatch(fetchOrdersTodayBeginAdmin());
  return fetch(`${baseUrl}/api/orders/date/todayadmin`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      API_KEY,
    },
    mode: 'cors',
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((json) => {
      dispatch(fetchOrdersTodaySuccessAdmin(json.orders));
      return json.orders;
    })
    .catch((error) => dispatch(fetchOrdersTodayFailureAdmin(error)));
};
