export const tp1519 = () => [
  "15:00",
  "15:05",
  "15:10",
  "15:15",
  "15:20",
  "15:25",
  "15:30",
  "15:35",
  "15:40",
  "15:45",
  "15:50",
  "15:55",
  "16:00",
  "16:05",
  "16:10",
  "16:15",
  "16:20",
  "16:25",
  "16:30",
  "16:35",
  "16:40",
  "16:45",
  "16:50",
  "16:55",
  "17:00",
  "17:05",
  "17:10",
  "17:15",
  "17:20",
  "17:25",
  "17:30",
  "17:35",
  "17:40",
  "17:45",
  "17:50",
  "17:55",
  "18:00",
  "18:05",
  "18:10",
  "18:15",
  "18:20",
  "18:25",
  "18:30",
  "18:35",
  "18:40",
  "18:45",
  "18:50",
  "18:55",
  "19:00",
];

export const tpWeekend = () => [
  "12:00",
  "12:05",
  "12:10",
  "12:15",
  "12:20",
  "12:25",
  "12:30",
  "12:35",
  "12:40",
  "12:45",
  "12:50",
  "12:55",
  "13:00",
  "13:05",
  "13:10",
  "13:15",
  "13:20",
  "13:25",
  "13:30",
  "13:35",
  "13:40",
  "13:45",
  "13:50",
  "13:55",
  "14:00",
  "14:05",
  "14:10",
  "14:15",
  "14:20",
  "14:25",
  "14:30",
  "14:35",
  "14:40",
  "14:45",
  "14:50",
  "14:55",
  "15:00",
  "15:05",
  "15:10",
  "15:15",
  "15:20",
  "15:25",
  "15:30",
  "15:35",
  "15:40",
  "15:45",
  "15:50",
  "15:55",
  "16:00",
  "16:05",
  "16:10",
  "16:15",
  "16:20",
  "16:25",
  "16:30",
  "16:35",
  "16:40",
  "16:45",
  "16:50",
  "16:55",
  "17:00",
  "17:05",
  "17:10",
  "17:15",
  "17:20",
  "17:25",
  "17:30",
  "17:35",
  "17:40",
  "17:45",
  "17:50",
  "17:55",
  "18:00",
  "18:05",
  "18:10",
  "18:15",
  "18:20",
  "18:25",
  "18:30",
  "18:35",
  "18:40",
  "18:45",
  "18:50",
  "18:55",
  "19:00",
  "19:05",
  "19:10",
  "19:15",
  "19:20",
  "19:25",
  "19:30",
  "19:35",
  "19:40",
  "19:45",
  "19:50",
  "19:55",
  "20:00",
];

export const tp1119 = () => [
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
  "19:00",
  "19:15",
  "19:30",
];

export const tpSat = () => [
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
  "19:00",
  "19:15",
  "19:30",
  "19:45",
  "20:00",
];

export const tpSun = () => [
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
  "19:00",
  "19:15",
  "19:30",
  "19:45",
  "20:00",
];

export const foodtruck1220 = () => [
  "12:00",
  "12:05",
  "12:10",
  "12:15",
  "12:20",
  "12:25",
  "12:30",
  "12:35",
  "12:40",
  "12:45",
  "12:50",
  "12:55",
  "13:00",
  "13:05",
  "13:10",
  "13:15",
  "13:20",
  "13:25",
  "13:30",
  "13:35",
  "13:40",
  "13:45",
  "13:50",
  "13:55",
  "14:00",
  "14:05",
  "14:10",
  "14:15",
  "14:20",
  "14:25",
  "14:30",
  "14:35",
  "14:40",
  "14:45",
  "14:50",
  "14:55",
  "15:00",
  "15:05",
  "15:10",
  "15:15",
  "15:20",
  "15:25",
  "15:30",
  "15:35",
  "15:40",
  "15:45",
  "15:50",
  "15:55",
  "16:00",
  "16:05",
  "16:10",
  "16:15",
  "16:20",
  "16:25",
  "16:30",
  "16:35",
  "16:40",
  "16:45",
  "16:50",
  "16:55",
  "17:00",
  "17:05",
  "17:10",
  "17:15",
  "17:20",
  "17:25",
  "17:30",
  "17:35",
  "17:40",
  "17:45",
  "17:50",
  "17:55",
  "18:00",
  "18:05",
  "18:10",
  "18:15",
  "18:20",
  "18:25",
  "18:30",
  "18:35",
  "18:40",
  "18:45",
  "18:50",
  "18:55",
  "19:00",
  "19:05",
  "19:10",
  "19:15",
  "19:20",
  "19:25",
  "19:30",
  "19:35",
  "19:40",
  "19:45",
  "19:50",
  "19:55",
  "20:00",
];

export const foodtruck121930 = () => [
  "12:00",
  "12:05",
  "12:10",
  "12:15",
  "12:20",
  "12:25",
  "12:30",
  "12:35",
  "12:40",
  "12:45",
  "12:50",
  "12:55",
  "13:00",
  "13:05",
  "13:10",
  "13:15",
  "13:20",
  "13:25",
  "13:30",
  "13:35",
  "13:40",
  "13:45",
  "13:50",
  "13:55",
  "14:00",
  "14:05",
  "14:10",
  "14:15",
  "14:20",
  "14:25",
  "14:30",
  "14:35",
  "14:40",
  "14:45",
  "14:50",
  "14:55",
  "15:00",
  "15:05",
  "15:10",
  "15:15",
  "15:20",
  "15:25",
  "15:30",
  "15:35",
  "15:40",
  "15:45",
  "15:50",
  "15:55",
  "16:00",
  "16:05",
  "16:10",
  "16:15",
  "16:20",
  "16:25",
  "16:30",
  "16:35",
  "16:40",
  "16:45",
  "16:50",
  "16:55",
  "17:00",
  "17:05",
  "17:10",
  "17:15",
  "17:20",
  "17:25",
  "17:30",
  "17:35",
  "17:40",
  "17:45",
  "17:50",
  "17:55",
  "18:00",
  "18:05",
  "18:10",
  "18:15",
  "18:20",
  "18:25",
  "18:30",
  "18:35",
  "18:40",
  "18:45",
  "18:50",
  "18:55",
  "19:00",
  "19:05",
  "19:10",
  "19:15",
  "19:20",
  "19:25",
  "19:30",
];

export const foodtruck16301930 = () => [
  "16:30",
  "16:35",
  "16:40",
  "16:45",
  "16:50",
  "16:55",
  "17:00",
  "17:05",
  "17:10",
  "17:15",
  "17:20",
  "17:25",
  "17:30",
  "17:35",
  "17:40",
  "17:45",
  "17:50",
  "17:55",
  "18:00",
  "18:05",
  "18:10",
  "18:15",
  "18:20",
  "18:25",
  "18:30",
  "18:35",
  "18:40",
  "18:45",
  "18:50",
  "18:55",
  "19:00",
  "19:05",
  "19:10",
  "19:15",
  "19:20",
  "19:25",
  "19:30",
];

export const tpWed = () => [
  "17:00",
  "17:05",
  "17:10",
  "17:15",
  "17:20",
  "17:25",
  "17:30",
  "17:35",
  "17:40",
  "17:45",
  "17:50",
  "17:55",
  "18:00",
  "18:05",
  "18:10",
  "18:15",
  "18:20",
  "18:25",
  "18:30",
  "18:35",
  "18:40",
  "18:45",
  "18:50",
  "18:55",
  "19:00",
  "19:05",
  "19:10",
  "19:15",
  "19:20",
  "19:25",
  "19:30",
];

export const tpWedUppsala2 = () => [
  "11:00",
  "11:05",
  "11:10",
  "11:15",
  "11:20",
  "11:25",
  "11:30",
  "11:35",
  "11:40",
  "11:45",
  "11:50",
  "11:55",
  "12:00",
  "12:05",
  "12:10",
  "12:15",
  "12:20",
  "12:25",
  "12:30",
  "12:35",
  "12:40",
  "12:45",
  "12:50",
  "12:55",
  "13:00",
  "13:05",
  "13:10",
  "13:15",
  "13:20",
  "13:25",
  "13:30",
  "13:35",
  "13:40",
  "13:45",
  "13:50",
  "13:55",
  "14:00",
];

export const tpWeekdays1114 = () => [
  "11:00",
  "11:05",
  "11:10",
  "11:15",
  "11:20",
  "11:25",
  "11:30",
  "11:35",
  "11:40",
  "11:45",
  "11:50",
  "11:55",
  "12:00",
  "12:05",
  "12:10",
  "12:15",
  "12:20",
  "12:25",
  "12:30",
  "12:35",
  "12:40",
  "12:45",
  "12:50",
  "12:55",
  "13:00",
  "13:05",
  "13:10",
  "13:15",
  "13:20",
  "13:25",
  "13:30",
  "13:35",
  "13:40",
  "13:45",
  "13:50",
  "13:55",
  "14:00",
];

export const tpWeekdays = () => [
  "11:00",
  "11:05",
  "11:10",
  "11:15",
  "11:20",
  "11:25",
  "11:30",
  "11:35",
  "11:40",
  "11:45",
  "11:50",
  "11:55",
  "12:00",
  "12:05",
  "12:10",
  "12:15",
  "12:20",
  "12:25",
  "12:30",
  "12:35",
  "12:40",
  "12:45",
  "12:50",
  "12:55",
  "13:00",
  "13:05",
  "13:10",
  "13:15",
  "13:20",
  "13:25",
  "13:30",
  "13:35",
  "13:40",
  "13:45",
  "13:50",
  "13:55",
  "14:00",
  "14:05",
  "14:10",
  "14:15",
  "14:20",
  "14:25",
  "14:30",
  "14:35",
  "14:40",
  "14:45",
  "14:50",
  "14:55",
  "15:00",
  "15:05",
  "15:10",
  "15:15",
  "15:20",
  "15:25",
  "15:30",
  "15:35",
  "15:40",
  "15:45",
  "15:50",
  "15:55",
  "16:00",
  "16:05",
  "16:10",
  "16:15",
  "16:20",
  "16:25",
  "16:30",
  "16:35",
  "16:40",
  "16:45",
  "16:50",
  "16:55",
  "17:00",
  "17:05",
  "17:10",
  "17:15",
  "17:20",
  "17:25",
  "17:30",
  "17:35",
  "17:40",
  "17:45",
  "17:50",
  "17:55",
  "18:00",
  "18:05",
  "18:10",
  "18:15",
  "18:20",
  "18:25",
  "18:30",
  "18:35",
  "18:40",
  "18:45",
  "18:50",
  "18:55",
  "19:00",
  "19:05",
  "19:10",
  "19:15",
  "19:20",
  "19:25",
  "19:30",
];

export const tpFri = () => [
  "11:00",
  "11:05",
  "11:10",
  "11:15",
  "11:20",
  "11:25",
  "11:30",
  "11:35",
  "11:40",
  "11:45",
  "11:50",
  "11:55",
  "12:00",
  "12:05",
  "12:10",
  "12:15",
  "12:20",
  "12:25",
  "12:30",
  "12:35",
  "12:40",
  "12:45",
  "12:50",
  "12:55",
  "13:00",
  "13:05",
  "13:10",
  "13:15",
  "13:20",
  "13:25",
  "13:30",
  "13:35",
  "13:40",
  "13:45",
  "13:50",
  "13:55",
  "14:00",
  "14:05",
  "14:10",
  "14:15",
  "14:20",
  "14:25",
  "14:30",
  "14:35",
  "14:40",
  "14:45",
  "14:50",
  "14:55",
  "15:00",
  "15:05",
  "15:10",
  "15:15",
  "15:20",
  "15:25",
  "15:30",
  "15:35",
  "15:40",
  "15:45",
  "15:50",
  "15:55",
  "16:00",
  "16:05",
  "16:10",
  "16:15",
  "16:20",
  "16:25",
  "16:30",
  "16:35",
  "16:40",
  "16:45",
  "16:50",
  "16:55",
  "17:00",
  "17:05",
  "17:10",
  "17:15",
  "17:20",
  "17:25",
  "17:30",
  "17:35",
  "17:40",
  "17:45",
  "17:50",
  "17:55",
  "18:00",
  "18:05",
  "18:10",
  "18:15",
  "18:20",
  "18:25",
  "18:30",
  "18:35",
  "18:40",
  "18:45",
  "18:50",
  "18:55",
  "19:00",
  "19:05",
  "19:10",
  "19:15",
  "19:20",
  "19:25",
  "19:30",
  "19:35",
  "19:40",
  "19:45",
  "19:50",
  "19:55",
  "20:00",
];

export const tpTisHoloTemp = () => [
  "16:00",
  "16:05",
  "16:10",
  "16:15",
  "16:20",
  "16:25",
  "16:30",
  "16:35",
  "16:40",
  "16:45",
  "16:50",
  "16:55",
  "17:00",
  "17:05",
  "17:10",
  "17:15",
  "17:20",
  "17:25",
  "17:30",
  "17:35",
  "17:40",
  "17:45",
  "17:50",
  "17:55",
  "18:00",
  "18:05",
  "18:10",
  "18:15",
  "18:20",
  "18:25",
  "18:30",
  "18:35",
  "18:40",
  "18:45",
  "18:50",
  "18:55",
  "19:00",
  "19:05",
  "19:10",
  "19:15",
  "19:20",
  "19:25",
  "19:30",
  "19:35",
  "19:40",
  "19:45",
  "19:50",
  "19:55",
  "20:00",
  "20:05",
  "20:10",
  "20:15",
  "20:20",
  "20:25",
  "20:30",
  "20:35",
  "20:40",
  "20:45",
  "20:50",
  "20:55",
  "21:00",
];

export const tpTorHoloTemp = () => [
  "16:00",
  "16:05",
  "16:10",
  "16:15",
  "16:20",
  "16:25",
  "16:30",
  "16:35",
  "16:40",
  "16:45",
  "16:50",
  "16:55",
  "17:00",
  "17:05",
  "17:10",
  "17:15",
  "17:20",
  "17:25",
  "17:30",
  "17:35",
  "17:40",
  "17:45",
  "17:50",
  "17:55",
  "18:00",
  "18:05",
  "18:10",
  "18:15",
  "18:20",
  "18:25",
  "18:30",
  "18:35",
  "18:40",
  "18:45",
  "18:50",
  "18:55",
  "19:00",
  "19:05",
  "19:10",
  "19:15",
  "19:20",
  "19:25",
  "19:30",
  "19:35",
  "19:40",
  "19:45",
  "19:50",
  "19:55",
  "20:00",
];

export const tpFriHoloTemp = () => [
  "16:00",
  "16:05",
  "16:10",
  "16:15",
  "16:20",
  "16:25",
  "16:30",
  "16:35",
  "16:40",
  "16:45",
  "16:50",
  "16:55",
  "17:00",
  "17:05",
  "17:10",
  "17:15",
  "17:20",
  "17:25",
  "17:30",
  "17:35",
  "17:40",
  "17:45",
  "17:50",
  "17:55",
  "18:00",
  "18:05",
  "18:10",
  "18:15",
  "18:20",
  "18:25",
  "18:30",
  "18:35",
  "18:40",
  "18:45",
  "18:50",
  "18:55",
  "19:00",
  "19:05",
  "19:10",
  "19:15",
  "19:20",
  "19:25",
  "19:30",
  "19:35",
  "19:40",
  "19:45",
  "19:50",
  "19:55",
  "20:00",
];

export const tpSatSunHoloTemp = () => [
  "14:00",
  "14:05",
  "14:10",
  "14:15",
  "14:20",
  "14:25",
  "14:30",
  "14:35",
  "14:40",
  "14:45",
  "14:50",
  "14:55",
  "15:00",
  "15:05",
  "15:10",
  "15:15",
  "15:20",
  "15:25",
  "15:30",
  "15:35",
  "15:40",
  "15:45",
  "15:50",
  "15:55",
  "16:00",
  "16:05",
  "16:10",
  "16:15",
  "16:20",
  "16:25",
  "16:30",
  "16:35",
  "16:40",
  "16:45",
  "16:50",
  "16:55",
  "17:00",
  "17:05",
  "17:10",
  "17:15",
  "17:20",
  "17:25",
  "17:30",
  "17:35",
  "17:40",
  "17:45",
  "17:50",
  "17:55",
  "18:00",
  "18:05",
  "18:10",
  "18:15",
  "18:20",
  "18:25",
  "18:30",
  "18:35",
  "18:40",
  "18:45",
  "18:50",
  "18:55",
  "19:00",
  "19:05",
  "19:10",
  "19:15",
  "19:20",
  "19:25",
  "19:30",
  "19:35",
  "19:40",
  "19:45",
  "19:50",
  "19:55",
  "20:00",
];

export const tpFriHolo = () => [
  "14:00",
  "14:05",
  "14:10",
  "14:15",
  "14:20",
  "14:25",
  "14:30",
  "14:35",
  "14:40",
  "14:45",
  "14:50",
  "14:55",
  "15:00",
  "15:05",
  "15:10",
  "15:15",
  "15:20",
  "15:25",
  "15:30",
  "15:35",
  "15:40",
  "15:45",
  "15:50",
  "15:55",
  "16:00",
  "16:05",
  "16:10",
  "16:15",
  "16:20",
  "16:25",
  "16:30",
  "16:35",
  "16:40",
  "16:45",
  "16:50",
  "16:55",
  "17:00",
  "17:05",
  "17:10",
  "17:15",
  "17:20",
  "17:25",
  "17:30",
  "17:35",
  "17:40",
  "17:45",
  "17:50",
  "17:55",
  "18:00",
  "18:05",
  "18:10",
  "18:15",
  "18:20",
  "18:25",
  "18:30",
  "18:35",
  "18:40",
  "18:45",
  "18:50",
  "18:55",
  "19:00",
  "19:05",
  "19:10",
  "19:15",
  "19:20",
  "19:25",
  "19:30",
  "19:35",
  "19:40",
  "19:45",
  "19:50",
  "19:55",
  "20:00",
  "20:05",
  "20:10",
  "20:15",
  "20:20",
  "20:25",
  "20:30",
  "20:35",
  "20:40",
  "20:45",
  "20:50",
  "20:55",
  "21:00",
];

export const tpSatHolo = () => [
  "12:00",
  "12:05",
  "12:10",
  "12:15",
  "12:20",
  "12:25",
  "12:30",
  "12:35",
  "12:40",
  "12:45",
  "12:50",
  "12:55",
  "13:00",
  "13:05",
  "13:10",
  "13:15",
  "13:20",
  "13:25",
  "13:30",
  "13:35",
  "13:40",
  "13:45",
  "13:50",
  "13:55",
  "14:00",
  "14:05",
  "14:10",
  "14:15",
  "14:20",
  "14:25",
  "14:30",
  "14:35",
  "14:40",
  "14:45",
  "14:50",
  "14:55",
  "15:00",
  "15:05",
  "15:10",
  "15:15",
  "15:20",
  "15:25",
  "15:30",
  "15:35",
  "15:40",
  "15:45",
  "15:50",
  "15:55",
  "16:00",
  "16:05",
  "16:10",
  "16:15",
  "16:20",
  "16:25",
  "16:30",
  "16:35",
  "16:40",
  "16:45",
  "16:50",
  "16:55",
  "17:00",
  "17:05",
  "17:10",
  "17:15",
  "17:20",
  "17:25",
  "17:30",
  "17:35",
  "17:40",
  "17:45",
  "17:50",
  "17:55",
  "18:00",
  "18:05",
  "18:10",
  "18:15",
  "18:20",
  "18:25",
  "18:30",
  "18:35",
  "18:40",
  "18:45",
  "18:50",
  "18:55",
  "19:00",
  "19:05",
  "19:10",
  "19:15",
  "19:20",
  "19:25",
  "19:30",
  "19:35",
  "19:40",
  "19:45",
  "19:50",
  "19:55",
  "20:00",
  "20:05",
  "20:10",
  "20:15",
  "20:20",
  "20:25",
  "20:30",
  "20:35",
  "20:40",
  "20:45",
  "20:50",
  "20:55",
  "21:00",
];

export const tpSunHolo = () => [
  "16:00",
  "16:05",
  "16:10",
  "16:15",
  "16:20",
  "16:25",
  "16:30",
  "16:35",
  "16:40",
  "16:45",
  "16:50",
  "16:55",
  "17:00",
  "17:05",
  "17:10",
  "17:15",
  "17:20",
  "17:25",
  "17:30",
  "17:35",
  "17:40",
  "17:45",
  "17:50",
  "17:55",
  "18:00",
  "18:05",
  "18:10",
  "18:15",
  "18:20",
  "18:25",
  "18:30",
  "18:35",
  "18:40",
  "18:45",
  "18:50",
  "18:55",
  "19:00",
  "19:05",
  "19:10",
  "19:15",
  "19:20",
  "19:25",
  "19:30",
  "19:35",
  "19:40",
  "19:45",
  "19:50",
  "19:55",
  "20:00",
  "20:05",
  "20:10",
  "20:15",
  "20:20",
  "20:25",
  "20:30",
  "20:35",
  "20:40",
  "20:45",
  "20:50",
  "20:55",
  "21:00",
];

export const tpSatSunGp = () => [
  "16:00",
  "16:05",
  "16:10",
  "16:15",
  "16:20",
  "16:25",
  "16:30",
  "16:35",
  "16:40",
  "16:45",
  "16:50",
  "16:55",
  "17:00",
  "17:05",
  "17:10",
  "17:15",
  "17:20",
  "17:25",
  "17:30",
  "17:35",
  "17:40",
  "17:45",
  "17:50",
  "17:55",
  "18:00",
  "18:05",
  "18:10",
  "18:15",
  "18:20",
  "18:25",
  "18:30",
  "18:35",
  "18:40",
  "18:45",
  "18:50",
  "18:55",
  "19:00",
  "19:05",
  "19:10",
  "19:15",
  "19:20",
  "19:25",
  "19:30",
  "19:35",
  "19:40",
  "19:45",
  "19:50",
  "19:55",
  "20:00",
];

export const tpSatSun = () => [
  "12:00",
  "12:05",
  "12:10",
  "12:15",
  "12:20",
  "12:25",
  "12:30",
  "12:35",
  "12:40",
  "12:45",
  "12:50",
  "12:55",
  "13:00",
  "13:05",
  "13:10",
  "13:15",
  "13:20",
  "13:25",
  "13:30",
  "13:35",
  "13:40",
  "13:45",
  "13:50",
  "13:55",
  "14:00",
  "14:05",
  "14:10",
  "14:15",
  "14:20",
  "14:25",
  "14:30",
  "14:35",
  "14:40",
  "14:45",
  "14:50",
  "14:55",
  "15:00",
  "15:05",
  "15:10",
  "15:15",
  "15:20",
  "15:25",
  "15:30",
  "15:35",
  "15:40",
  "15:45",
  "15:50",
  "15:55",
  "16:00",
  "16:05",
  "16:10",
  "16:15",
  "16:20",
  "16:25",
  "16:30",
  "16:35",
  "16:40",
  "16:45",
  "16:50",
  "16:55",
  "17:00",
  "17:05",
  "17:10",
  "17:15",
  "17:20",
  "17:25",
  "17:30",
  "17:35",
  "17:40",
  "17:45",
  "17:50",
  "17:55",
  "18:00",
  "18:05",
  "18:10",
  "18:15",
  "18:20",
  "18:25",
  "18:30",
  "18:35",
  "18:40",
  "18:45",
  "18:50",
  "18:55",
  "19:00",
  "19:05",
  "19:10",
  "19:15",
  "19:20",
  "19:25",
  "19:30",
  "19:35",
  "19:40",
  "19:45",
  "19:50",
  "19:55",
  "20:00",
];

export const tpMondayPolhem = () => [
  "11:00",
  "11:05",
  "11:10",
  "11:15",
  "11:20",
  "11:25",
  "11:30",
  "11:35",
  "11:40",
  "11:45",
  "11:50",
  "11:55",
  "12:00",
  "12:05",
  "12:10",
  "12:15",
  "12:20",
  "12:25",
  "12:30",
  "12:35",
  "12:40",
  "12:45",
  "12:50",
  "12:55",
  "13:00",
  "13:05",
  "13:10",
  "13:15",
  "13:20",
  "13:25",
  "13:30",
  "13:35",
  "13:40",
  "13:45",
  "13:50",
  "13:55",
  "14:00",
];

export const tpFoodtruck = () => [
  "16:00",
  "16:05",
  "16:10",
  "16:15",
  "16:20",
  "16:25",
  "16:30",
  "16:35",
  "16:40",
  "16:45",
  "16:50",
  "16:55",
  "17:00",
  "17:05",
  "17:10",
  "17:15",
  "17:20",
  "17:25",
  "17:30",
  "17:35",
  "17:40",
  "17:45",
  "17:50",
  "17:55",
  "18:00",
  "18:05",
  "18:10",
  "18:15",
  "18:20",
  "18:25",
  "18:30",
  "18:35",
  "18:40",
  "18:45",
  "18:50",
  "18:55",
  "19:00",
  "19:05",
  "19:10",
  "19:15",
  "19:20",
  "19:25",
  "19:30",
];
