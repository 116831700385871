/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
/* eslint-disable comma-dangle */
/* eslint-disable radix */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { baseUrl, API_KEY, postData } from "../util";
import logo from "../assets/spin.gif";
import * as Svgs from "../assets";

import "./Payment.css";

const Payment = () => {
  const [responseHeaderText, setResponseHeaderText] =
    useState("Slutför betalning");
  const [paymentStatus, setPaymentStatus] = useState("WAITING");
  const [myOrder, setMyOrder] = useState({});
  const history = useHistory();
  const location = useLocation();
  let getSwishInterval;
  let i = 0;
  const lastSuccessfulOrderId = localStorage.getItem("lastOrderId");

  useEffect(() => {
    window.scrollTo(0, 0);
    const { order } = location.state;
    setMyOrder(order);
    orderAlreadyPaid(order);
    return () => clearInterval(getSwishInterval);
  }, []);

  const orderAlreadyPaid = (order) => {
    const newId = order.orderId && parseInt(order.orderId.substring(4));
    const currentId =
      lastSuccessfulOrderId && parseInt(lastSuccessfulOrderId.substring(4));

    if (newId == currentId) {
      setPaymentStatus("PAID");
      setResponseHeaderText("Tack för din order!");
    }
  };

  const errorMsgHandler = (status) => {
    if (status === "DECLINED") {
      setResponseHeaderText("Betalningen avbröts av användaren");
    } else if (status === "ERROR") {
      setResponseHeaderText(
        "Något oväntat gick fel. Försök igen eller kontakta support@sot.nu"
      );
    }
    setPaymentStatus(status);
  };

  const goHome = () => {
    clearInterval(getSwishInterval);
    localStorage.setItem("orderList", JSON.stringify([]));
    localStorage.setItem("discountedOrderSum", 0);
    localStorage.setItem("discountedOrderList", JSON.stringify([]));
    localStorage.setItem("deliveryTime", null);
    localStorage.setItem("orderSum", 0);
    localStorage.setItem("selectedStore", "Välj ställe för avhämtning");
    window.location.href = "/";
  };

  const getPaymentStatus = (id) => {
    const url = `${baseUrl}/api/transactions/paymentrequests/${id}`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        API_KEY,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "PAID") {
          // här skulle man kunna lägga postdata swish, men farligt ifall de inte går tillbaka i appen
          clearInterval(getSwishInterval);
          localStorage.setItem("lastOrderId", myOrder.orderId);
          setResponseHeaderText("Tack för din order!");
          Swal.fire({
            title: "",
            text: "Tack för din order. Vi har skickat dig ett mail med bekräftelse på din order. Kontakta support@sot.nu om du inte fått något mail inom 3 minuter",
            icon: "success",
            confirmButtonColor: "#10AD7D",
            confirmButtonText: "OK",
          }).then(() =>
            Swal.fire(
              "",
              "Vi har även en foodtruck för event, kontakta oss på info@sot.nu",
              "info"
            )
          );
        }

        if (json.status !== "CREATED") {
          clearInterval(getSwishInterval);
          errorMsgHandler(json.status);
        }
      })
      .catch((error) => {
        console.log("Request failure: ", error);
      });
  };

  const canOpenSwish = () => {
    const { standalone } = window.navigator;
    const userAgent = window.navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
      if (!standalone && safari) {
        // Safari
        console.log("inside regular safari");
        window.location.href = "swish://callbackurl=https://sot.nu/payment";
      } else if (!standalone && !safari) {
        // iOS webview
        console.log("inside web view ios");
      }
    } else if (userAgent.includes("wv")) {
      // Android webview
      console.log("inside webview android");
    } else if (safari) {
      // do nothing
      console.log("desktop safari");
    } else {
      // Chrome
      console.log("inside regular chrome");
      window.location.href = "swish://callbackurl=https://sot.nu/payment";
    }
  };

  const postPayment = (payer, amount, message) => {
    postData("/api/orders/order", { order: myOrder }).then((orderRes) => {
      if (orderRes.status == 200) {
        setPaymentStatus("CREATED");
        setResponseHeaderText("Väntar på swishbetalning");
        const url = `${baseUrl}/api/transactions/paymentrequests`;
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            API_KEY,
          },
          body: JSON.stringify({
            payerAlias: payer,
            amount,
            message,
          }),
        })
          .then((response) => {
            if (response.status !== 201) {
              setResponseHeaderText(
                "Något gick fel när vi försökte hämta din swishbetalning. Försök igen eller kontakta support@sot.nu"
              );
              setPaymentStatus("ERROR");
              return;
            }
            return response.json();
          })
          .then((json) => {
            if (json) {
              const identifier = json.id;
              canOpenSwish();
              getSwishInterval = setInterval(() => {
                if (i < 120) {
                  getPaymentStatus(identifier);
                }
                if (i === 120) {
                  clearInterval(getSwishInterval);
                }
                i++;
              }, 1000);
              return json;
            }
          })
          .catch((error) => {
            // console.log('Request failure: ', error);
          });
      }
    });
  };

  const { payer, amount, reference, order } = location.state;
  const showLoader =
    paymentStatus !== "PAID" &&
    paymentStatus !== "DECLINED" &&
    paymentStatus !== "ERROR" &&
    paymentStatus !== "WAITING";
  return (
    <div>
      <div className="cart_headerRow">
        <h2 className="header2">
          <div className="loading_header_div">
            {responseHeaderText}{" "}
            {showLoader && (
              <img src={logo} alt="" className="loader_spinner_small" />
            )}
          </div>
        </h2>
        <div className="cart_headerBackButtonContainer">
          {paymentStatus == "WAITING" && (
            <button
              type="button"
              className="pizzas_headerBackButton"
              onClick={() => history.push("/checkout")}
            >
              <img src={Svgs.leftArrow} alt="" />
            </button>
          )}
        </div>
        <div className="cart_headerRow" />
        {myOrder.orderId != null && paymentStatus == "WAITING" && (
          <div className="payment_order_init_div">
            <div>
              <span className="payment_sub_header">Orderdetaljer</span>
              <br />
              <span>Tid för upphämtning: {myOrder.deliveryTime}</span>
              <p dangerouslySetInnerHTML={{ __html: myOrder.pizzaListMail }} />
              <span>Upphämtningsplats: {myOrder.store}</span>
              <br />
              {myOrder.campaign.length > 0 && (
                <div>
                  <span>Rabattkod: {myOrder.campaign}</span>
                  <br />
                </div>
              )}
              <span>Summa: {myOrder.orderPrice} SEK</span>
              <br />
              <span>Mail för kvitto: {myOrder.email}</span>
              <br />
              <br />
            </div>

            <h4 className="payment_info_text">
              Tryck på knappen nedan för att initera en Swishbetalning med 0
              {location.state.payer.substring(2)}
            </h4>

            <div className="home_toMenuButtonContainer">
              <button
                type="button"
                className="home_toMenuButton"
                onClick={() => postPayment(payer, amount, reference, order)}
              >
                Initiera Swishbetalning
              </button>
            </div>
          </div>
        )}
        <br />
        {myOrder.orderId != null && paymentStatus === "CREATED" && (
          <h3 className="payment_info_text">
            Slutför betalningen med Swish på din mobil och gå sedan tillbaka
            till denna sida. Vid problem kontakta support@sot.nu
          </h3>
        )}
        {myOrder.orderId != null && paymentStatus === "DECLINED" && (
          <h3 className="payment_info_text">
            Swishbetalningen avbröts. Gå tillbaka och försök igen.
          </h3>
        )}

        {myOrder.orderId != null && paymentStatus === "PAID" && (
          <div className="payment_order_init_div">
            <div>
              <span>
                Ordernummer:{" "}
                {myOrder.orderId.substring(myOrder.orderId.length - 6)}
              </span>
              <br />
              <span>
                Din order är redo att upphämtas: {myOrder.deliveryTime}
              </span>
              <p dangerouslySetInnerHTML={{ __html: myOrder.pizzaListMail }} />
              <span>Upphämtningsplats: {myOrder.store}</span>
              <br />
              {myOrder.campaign.length > 0 && (
                <div>
                  <span>Rabattkod: {myOrder.campaign}</span>
                  <br />
                </div>
              )}
              <span>Mail för kvitto: {myOrder.email}</span>
              <br />
              <br />
              <br />
              <span>Datum för köp: {myOrder.orderDate}</span>
              <br />
              <span>Summa: {myOrder.orderPrice} SEK</span>
              <br />
              <span>
                Varav 12% moms: {Math.floor(myOrder.orderPrice * 0.1071)} SEK
              </span>
              <br />
              <span>sotig AB 559213-5098</span>
              <br />
              <br />
              <span>Tack för din order / SOT</span>
            </div>
          </div>
        )}
        <br />
        <br />
        <br />
        {paymentStatus !== "CREATED" && paymentStatus !== "WAITING" && (
          <div className="home_toMenuButtonContainer">
            <button
              type="button"
              className="home_toMenuButton"
              onClick={() => goHome()}
            >
              Tillbaka till startsidan
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Payment;
