import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './components/Home';
import Pizzas from './components/Pizzas';
import Cart from './components/Cart';
import Checkout from './components/Checkout';
import AdminStats from './components/AdminStats';
import Admin from './components/Admin';
import AdminLogin from './components/AdminLogin';
import Payment from './components/Payment';

const RouteHandler = () => (
  <Switch>
    <Route path="/pizzas">
      <Pizzas />
    </Route>
    <Route path="/cart">
      <Cart />
    </Route>
    <Route path="/checkout">
      <Checkout />
    </Route>
    <Route path="/payment">
      <Payment />
    </Route>
    <Route path="/admin/login">
      <AdminLogin />
    </Route>
    <Route path="/admin/stats">
      <AdminStats />
    </Route>
    <Route path="/admin">
      <Admin />
    </Route>
    <Route path="/">
      <Home />
    </Route>
    <Redirect to="/" />
  </Switch>
);

export default RouteHandler;
