/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/prop-types */
import React from 'react';
import './InfoModal.css';

const InfoModal = ({ closeInfoModal }) => (
  <div className="infoModal_modalDiv">
  <div className="infoModal_innerModalDiv">
    <div className="infoModal_headerRow">
      <button className="infoModal_closeButton" onClick={() => closeInfoModal()}>Stäng [X]</button>
      <h3 className="infoModal_header">Köpvillkor</h3>
    </div>
    <div>
      <br />
      <br />
      <br />
      <br />
      <span className="infoModal_text">

        <h4>1. Företagsinformation</h4>

        <p>sot.nu tillhandahålls av sotig AB med Org nummer 5592135098. Information & bilder på hemsidan ägs av företaget eller partner och får inte kopieras utan tillåtelse.</p>
        <span>sotig AB</span>
        <br />
        <span>Nyhammar 2</span>
        <br />
        <span>15532 Nykvarn</span>
        <br />
        <span>Sweden</span>

        <h4>2. Priser</h4>
        <span>Varje vara anges inklusive moms i Svenska kronor. I kundvagnen ser man det totala priset inklusive alla avgifter så som moms och betalningar.</span>
        <br />

        <h4>3.1 Betalning</h4>
        <span>Betalning sker genom vald betalningsmetod i kassan. Kortbetalningsmetoden tillhandahålls av Bambora tillsammans med samarbetspartners.</span>

        <h4>3.2 Kortbetalning</h4>
        <span>Korbetalning accepteras av Visa, MasterCard och Maestro. Debitering av ditt betalkort sker direkt</span>

        <h4>7. Personuppgiftsbehandling</h4>
        <span>För att kunna ta emot, hantera och leverera din beställning kommer sotig AB att behandla dina personuppgifter. All vår behandling av personuppgifter sker i enlighet med gällande personuppgiftslagstiftning. Du kan läsa mer om hur vi behandlar dina personuppgifter under Bilaga 1, Personuppgiftsbehandling.</span>
        <h4>8. Kontakt & Kundtjänst</h4>

        <a href="mailto: info@sot.nu">info@sot.nu</a>
        <br />
        <br />
        <p>Bilaga 1, Personuppgiftsbehandling</p>

        <span>sotig AB, org. nr. 5592135098, behandlar personuppgifter i samband med handeln på https://sot.nu och är personuppgiftsansvarigt för behandlingen. Personuppgifter behandlas för att sotig AB ska kunna tillhandahålla sina tjänster, huvudsakligen för följande ändamål (”Ändamålen”):</span>
        <br />
        För att skapa och upprätthålla ditt personliga konto.
        <br />
        För att ta emot och bearbeta dina beställningar och skicka dina varor till dig.
        <br />
        För att hantera eventuella returer.
        <br />
        För att skicka dig sms-aviseringar om leveransstatus.
        <br />
        För att besvara dina frågor och informera dig om nya eller ändrade tjänster.
        <br />
        För att skicka marknadsföringserbjudanden som nyhetsbrev och kataloger.
        <br />
        För att skicka dig enkäter som ger dig möjlighet att påverka Test AB:s erbjudanden och tjänster.
        <br />
        De uppgifter som behandlas är de uppgifter som du anger, t.ex. när du lägger beställningar, kontaktar kundtjänst eller dylikt. De uppgifter du anger omfattar t.ex. kontaktinformation, leveransadress och beställda varor (”Personuppgifterna”). Personuppgifterna är nödvändiga för att Sotig AB ska kunna uppnå Ändamålen. Viss information kan även komma att inhämtas från offentliga register eller från andra allmänt tillgängliga källor.
        <br />
        Den huvudsakliga rättsliga grunden för behandlingen är att behandlingen är nödvändig för att Sotig AB ska kunna fullgöra sina åtaganden gentemot sina kunder. Vissa behandlingar baseras även på Sotig AB:s berättigade intresse att kunna bedriva sin verksamhet på ett effektivt och säkert sätt samt på Sotig AB:s skyldighet att följa rättsliga förpliktelser. Personuppgifterna kan komma att delas med myndigheter och andra parter som Sotig AB anlitar för att uppnå Ändamålen, t.ex. för leverans. Dessa aktörer kan vara baserade i länder utanför EU/EES. Om Europakommissionen inte anser att landet säkerställer en adekvat skyddsnivå kommer överföringen till den tredje parten att ha stöd i kommissionens standardavtalsklausuler för överföring av personuppgifter till ett land utanför EU/EES, se artiklarna 45–46 i förordning 2016/679 (”GDPR”). Dessa standardavtalsklausuler finns åtkomliga här: http://ec.europa.eu/justice/data-protection/international-transfers/transfer/. Behandlingen av Personuppgifterna kommer pågå så länge det krävs för att uppfylla Ändamålen eller så länge som Sotig AB är förpliktade att göra detta. Därefter raderas Personuppgifterna.
        <br />
        Om du har några frågor kring behandlingen av Personuppgifterna kan du kontakta Sotig AB via support@sot.nu. Du kan även använda denna adress om du vill utöva någon av de rättigheter du enligt GDPR har som registrerad. Vänligen notera att rättigheterna enligt GDPR inte är absoluta och att ett åberopande av en rättighet därför inte nödvändigtvis leder till en åtgärd. Dina rättigheter enligt GDPR inkluderar följande:
        <br />
        <br />
        <b>Rätt till tillgång</b> – Enligt artikel 15 GDPR har du rätt att ta del av Personuppgifterna och att bli informerad om behandlingen. Den informationen finns i detta dokument.
        <br />
        <b>Rätt till rättelse</b> – Enligt artikel 16 GDPR har du rätt att utan onödigt dröjsmål få felaktiga personuppgifter som rör dig rättade. Med beaktande av ändamålet med behandlingen har du också rätt att komplettera ofullständiga personuppgifter.
        <br />
        <b>Rätt till radering (”rätten att bli bortglömd”) </b> – Under vissa omständigheter har du med stöd av artikel 17 GDPR rätt att få Personuppgifterna raderade.
        <br />
        <b>Rätt till begränsning av användning</b> – Under vissa omständigheter har du enligt artikel 18 GDPR rätt att begränsa behandlingen av Personuppgifterna.
        <br />
        <b>Rätt till dataportabilitet</b> – Enligt artikel 20 GDPR har du rätt att få ut Personuppgifterna i ett strukturerat, allmänt använt och maskinläsbart format. Du har också rätt att överföra dessa uppgifter till en annan personuppgiftsansvarig.
        <br />
        <b>Rätt att göra invändningar</b> – Enligt artikel 21 GDPR har du rätt att invända mot viss behandling av Personuppgifterna, exempelvis sådan behandling som grundar sig i Test AB:s berättigade intressen.
        <br />
        <br />
        Slutligen har du också rätt att inge klagomål till Datainspektionen, som kan kontaktas via datainspektionen@datainspektionen.se eller 08-657 61 00.
      </span>
      <br/>
      <br/>
      <div className="infoModal_okButtonDiv">
        <button className="infoModal_okButton" onClick={() => closeInfoModal()}>OK!</button>
      </div>
      <br/>
    </div>
    </div>
  </div>
);

export default InfoModal;
