import {
  FETCH_CAMPAIGNS_BEGIN,
  FETCH_CAMPAIGNS_SUCCESS,
  FETCH_CAMPAIGNS_FAILURE,
} from '../actions/campaignsActions';

const initialState = {
  campaigns: [],
  campaignsLoading: false,
  campaignsError: null,
};

const campaignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CAMPAIGNS_BEGIN:
      return {
        ...state,
        campaignsLoading: true,
        campaignsError: null,
      };

    case FETCH_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaignsLoading: false,
        campaigns: action.payload.campaigns,
      };

    case FETCH_CAMPAIGNS_FAILURE:
      return {
        ...state,
        campaignsLoading: false,
        campaignsError: action.payload.error,
        campaigns: [],
      };

    default:
      return state;
  }
};

export default campaignsReducer;
