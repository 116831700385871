import { baseUrl, API_KEY, handleErrors } from '../../util';

export const FETCH_CAMPAIGNS_BEGIN = 'FETCH_CAMPAIGNS_BEGIN';
export const FETCH_CAMPAIGNS_SUCCESS = 'FETCH_CAMPAIGNS_SUCCESS';
export const FETCH_CAMPAIGNS_FAILURE = 'FETCH_CAMPAIGNS_FAILURE';

export const fetchCampaignsBegin = () => ({
  type: FETCH_CAMPAIGNS_BEGIN,
});

export const fetchCampaignsSuccess = (campaigns) => ({
  type: FETCH_CAMPAIGNS_SUCCESS,
  payload: { campaigns },
});

export const fetchCampaignsFailure = (error) => ({
  type: FETCH_CAMPAIGNS_FAILURE,
  payload: { error },
});

export const fetchCampaigns = () => (dispatch) => {
  dispatch(fetchCampaignsBegin());
  return fetch(`${baseUrl}/api/campaigns`, {
    headers: {
      'Content-Type': 'application/json',
      API_KEY,
    },
    method: 'GET',
    mode: 'cors',
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((json) => {
      dispatch(fetchCampaignsSuccess(json.campaigns));
      return json.campaigns;
    })
    .catch((error) => dispatch(fetchCampaignsFailure(error)));
};
